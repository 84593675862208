import IconButton from '@mui/material/IconButton';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import {
  gridDetailPanelExpandedRowIdsSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRowId,
  gridRowsLookupSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { Tooltip } from 'common/components/Tooltip/Tooltip';

export const CollapseAllButton = () => {
  const gridApi = useGridApiContext();
  const expandedRows = useGridSelector(gridApi, gridDetailPanelExpandedRowIdsSelector);
  const rowsWithDetailPanels = useGridSelector(
    gridApi,
    gridDetailPanelExpandedRowsContentCacheSelector,
  );

  const noDetailPanelsOpen = expandedRows.length === 0;

  const expandOrCollapseAll = () => {
    const dataRowIdToModelLookup = gridRowsLookupSelector(gridApi);
    const allRowIdsWithDetailPanels: GridRowId[] = Object.keys(rowsWithDetailPanels).map((key) =>
      gridApi.current.getRowId(dataRowIdToModelLookup[key]),
    );

    gridApi.current.setExpandedDetailPanels(noDetailPanelsOpen ? allRowIdsWithDetailPanels : []);
  };

  return (
    <Tooltip title={noDetailPanelsOpen ? 'Expand All' : 'Collapse All'} variant="dark">
      <IconButton
        size="small"
        aria-label={noDetailPanelsOpen ? 'Expand All' : 'Collapse All'}
        onClick={expandOrCollapseAll}
      >
        {noDetailPanelsOpen ? <UnfoldMoreRoundedIcon /> : <UnfoldLessRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
};
