import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ContentCopyExtension } from '../../ContentCopyExtension/ContentCopyExtension';
import Box from '@mui/system/Box';
import { styled } from '@mui/material';
import { Tooltip } from '../Tooltip/Tooltip';

export type SectionGridItemProps = {
  label: string;
  value?: string | null;
  testId?: string;
  children?: React.ReactElement;
  xsSize?: number;
  smSize?: number;
  mdSize?: number;
  lgSize?: number;
  xlSize?: number;
};

export type CustomGridSizes = Pick<
  SectionGridItemProps,
  'xsSize' | 'smSize' | 'mdSize' | 'lgSize' | 'xlSize'
>;

const GridStyled = styled(Grid)({
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  overflow: 'hidden',
  minWidth: 0,
});

export const SectionGridItem: React.FC<
  SectionGridItemProps & { customGridSizes?: CustomGridSizes | null }
> = ({ label, value, testId, children, smSize, mdSize, lgSize, xlSize, customGridSizes }) => {
  const gridSizes = {
    xs: customGridSizes?.xsSize || 12,
    sm: customGridSizes?.smSize || smSize || 6,
    md: customGridSizes?.mdSize || mdSize || 4,
    lg: customGridSizes?.lgSize || lgSize || 3,
    xl: customGridSizes?.xlSize || xlSize || 2,
  };

  return value !== undefined || children ? (
    <Grid item {...gridSizes}>
      <Grid container alignItems="center" spacing={1} columns={1}>
        <GridStyled
          item
          xs={6}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          <Tooltip
            title={label}
            showArrow={true}
            variant="dark"
            placement={'bottom'}
            enterDelay={1000}
            enterTouchDelay={1000}
            enterNextDelay={1000}
          >
            <Typography variant="text2Secondary">{label}</Typography>
          </Tooltip>
        </GridStyled>
        <GridStyled item xs={6}>
          {!children && (
            <Typography variant="text1Primary" data-testid={testId}>
              <ContentCopyExtension value={value || '-'}>
                <Tooltip
                  title={value || '-'}
                  showArrow={true}
                  variant="dark"
                  placement={'bottom'}
                  enterDelay={1000}
                  enterTouchDelay={1000}
                  enterNextDelay={1000}
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'noWrap',
                    }}
                  >
                    {value || '-'}
                  </Box>
                </Tooltip>
              </ContentCopyExtension>
            </Typography>
          )}
          {children && <Box data-testid={testId}>{children}</Box>}
        </GridStyled>
      </Grid>
    </Grid>
  ) : null;
};
