import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';
import { RSIM_TABLE_CONFIGURATION } from './models/configurations';
import { KickRsimProvider } from './KickRsimProvider';
import { Routes } from 'Routes.constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSorting } from '../../common/hooks/useSorting';
import { useRSimInventory } from 'rSimInventory/hooks/useRSimInventory';
import { Actions, Subjects } from 'permissions/ability';
import { ConfigurableTable } from 'common/components/ConfigurableTable/ConfigurableTable';
import { TextCell } from 'common/components/ConfigurableTable/Components/Cells/TextCell';
import { RSimSimTable } from './SimInventory/RSimSimTable';
import { DetailsPanel } from 'common/components/ConfigurableTable/Components/Containers/DetailsPanel';
import { RSimStatusCell } from './Cells/RSimStatusCell';
import { RSimEditDialog } from './Dialogs/RSimEditDialog';
import { RSimUpdateProvider } from './Providers/RSimUpdateProvider';
import { TagsCell } from './Cells/TagsCell';
import { RSimConfigurationDialog } from './Dialogs/RSimConfigurationDialog';
import { DurationCell } from 'common/components/ConfigurableTable/Components/Cells/DurationCell';
import { RSimsSimsProvider } from './Providers/RSimsSimsProvider';
import { OtaCell } from './Cells/OtaCell';
import { ID_SEARCH_LIMIT } from './Inputs/RSimIdSearchInput';
import { ID_SEARCH_REGEX } from '../../common/components/Inputs/IdAdvanceSearch/MultipleIdInput';
import { RSimEIDLinkCell } from './Cells/RSimEIDLinkCell';
import { useRSimFilters } from 'rSimInventory/Filters/hooks/useRSimFilters';

const CustumizedConfigurationTable = styled(ConfigurableTable)({
  '& .MuiDataGrid-columnHeader[data-field="rSimStatus"] .MuiTypography-tableHeaderPrimary': {
    textTransform: 'none',
  },
});

export const RSimTable = () => {
  const { filters } = useRSimFilters();

  const {
    searchText,
    chosenIdType,
    lastActiveProfile,
    lastUpdateTime,
    tags,
    connectionId,
    orderNumber,
    simulateOutage,
    accounts,
    eidFrom,
    eidTo,
  } = filters;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sorting, setSortParams } = useSorting('rsim-order');

  const searchIds = useMemo(() => {
    const results = searchText.match(ID_SEARCH_REGEX) || [''];
    return results.slice(0, ID_SEARCH_LIMIT);
  }, [searchText]);

  const {
    queryResult,
    pagination: { page, setPage, rowsPerPage, setRowsPerPage, totalNumberOfItems },
    queryKey,
  } = useRSimInventory({
    searchIds: searchIds,
    searchText,
    chosenIdType,
    lastActiveProfile,
    lastUpdateTime,
    tags,
    connectionId,
    orderNumber,
    simulateOutage,
    accounts,
    eidFrom,
    eidTo,
    sorting,
  });

  const euiccs =
    useMemo(() => queryResult.data?.items.map((item) => item.euicc), [queryResult.data]) || [];

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  return (
    <KickRsimProvider euiccs={euiccs}>
      <RSimUpdateProvider queryKey={queryKey}>
        <RSimsSimsProvider rsims={queryResult.data?.items}>
          <CustumizedConfigurationTable
            disableColumnReorder
            actions={[
              {
                label: 'View Details',
                type: 'custom',
                actionCallback: (data: any) => {
                  navigate(`${Routes.rSimInventory}/${data.euicc}`, {
                    state: {
                      previousPath: `${pathname}${search}`,
                    },
                  });
                },
              },
              {
                id: 'edit',
                label: 'Edit',
                type: 'dialog',
                Component: RSimEditDialog,
                permission: {
                  action: Actions.edit,
                  subject: Subjects.tags,
                },
              },
              {
                id: 'otaConfiguration',
                label: t('rSimInventory.configurationDetails'),
                type: 'dialog',
                Component: RSimConfigurationDialog,
                permission: {
                  action: Actions.edit,
                  subject: Subjects.otaConfiguration,
                },
              },
            ]}
            data-testid="rsims inventory table"
            configuration={RSIM_TABLE_CONFIGURATION}
            getDetailPanelContent={(props) => {
              return (
                <DetailsPanel>
                  <RSimSimTable
                    euicc={props.row.euicc}
                    primaryIccid={props.row.primaryIccid}
                    secondaryIccid={props.row.secondaryIccid}
                    status={props.row.status}
                  />
                </DetailsPanel>
              );
            }}
            getDetailPanelHeight={() => 176}
            rows={queryResult.data?.items || []}
            cellConfiguration={{
              default: TextCell,
              euicc: RSimEIDLinkCell,
              rSimStatus: RSimStatusCell,
              tags: TagsCell,
              appliedOtaConfigurationProfile: OtaCell,
              inMinuteUsage: DurationCell,
            }}
            totalNumberOfItems={totalNumberOfItems}
            onPaginationChange={(pagination) => {
              if (pagination.page !== page) {
                setPage(pagination.page);
              }

              if (pagination.pageSize !== rowsPerPage) {
                setRowsPerPage(pagination.pageSize);
              }
            }}
            page={page}
            pageSize={rowsPerPage}
            onSortModelChange={(model) => {
              const [sortField] = model;
              //temporary solution
              if (sortField.field === 'euicc') {
                setSortParams(`EID:${sortField.sort}`.toUpperCase());
              } else {
                setSortParams(`${sortField.field}:${sortField.sort}`.toUpperCase());
              }
            }}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'euicc',
                    sort: 'asc',
                  },
                ],
              },
            }}
          />
        </RSimsSimsProvider>
      </RSimUpdateProvider>
    </KickRsimProvider>
  );
};
